import React from 'react';
import MoneyBack from '../assets/money-back.svg'
import Safety from '../assets/safety.svg'
import Security from '../assets/security.svg'

const Advantages = ({translations}) => {
    return (
        <div className="advantages">
            <div className="advantages_item">
                <img src={MoneyBack} alt=""/>
                <h3>{translations.moneyBack}</h3>
                <p>{translations.canRequestRefund}</p>
            </div>
            <div className="advantages_item">
                <img src={Safety} alt=""/>
                <h3>{translations.safetyGuarantee}</h3>
                <p>{translations.personalInfo}</p>
            </div>
            <div className="advantages_item">
                <img src={Security} alt=""/>
                <h3>{translations.securityGuarantee}</h3>
                <p>{translations.allTransactions}</p>
            </div>
        </div>
    )
};

export default Advantages
