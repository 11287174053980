import React from 'react';

const Modal = ({toggleModal, isShowModal, translations}) => {
    const handleClick = () => {
        toggleModal(false);
        smoothScroll()
    }
    const smoothScroll = () => {
        window.scrollTo({top: 0, behavior: 'smooth'});
    }
    if (!isShowModal) {
        return null
    }

    return (
        <div className="modal_wrap">
            <div className="modal_content">
                <h3>{translations.appAccepted}</h3>
                <p>{translations.weWillReply}</p>
                <button onClick={handleClick}>{translations.ok}!👌</button>
            </div>
        </div>
    )
}

export default Modal
