import React from 'react'

const Header = () => {

    return (
        <header className="header_wrap">
            <div className="header">
                <svg width="152" height="12" viewBox="0 0 152 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 0.265194V11.7127H2.32044V9.25967H4.1768C6.58564 9.25967 8.48619 7.20442 8.48619 4.77348C8.48619 2.34254 6.58564 0.265194 4.1768 0.265194H0ZM2.32044 2.58564H4.1768C5.25967 2.58564 6.16575 3.51381 6.16575 4.77348C6.16575 6.01105 5.25967 6.93923 4.1768 6.93923H2.32044V2.58564Z" fill="black"/>
                    <path d="M12.7441 0.265194L8.58943 11.7127H11.0646L11.9486 9.25967H16.0369L16.9209 11.7127H19.3961L15.2193 0.265194H12.7441ZM13.9817 3.66851L15.1751 6.93923H12.8104L13.9817 3.66851Z" fill="black"/>
                    <path d="M18.5169 0.265194L22.4727 8.1768V11.7127H24.7932V8.1768L28.749 0.265194H26.1412L23.6219 5.30387L21.1247 0.265194H18.5169Z" fill="black"/>
                    <path d="M30.1925 0.265194V11.7127H32.513V4.92818L36.0047 9.98895L39.4743 4.92818V11.7127H41.8168V0.265194H39.8942L36.0047 5.92265L32.1152 0.265194H30.1925Z" fill="black"/>
                    <path d="M43.5946 0.287293V11.7127H51.1526V9.39227H45.9151V7.07182H50.9537V4.92818H45.9151V2.60773H51.1526V0.287293H43.5946Z" fill="black"/>
                    <path d="M52.9178 0.265194V11.7127H55.2383V4.79558L60.52 11.7127H62.5974V0.265194H60.2548V7.20442L54.9952 0.265194H52.9178Z" fill="black"/>
                    <path d="M64.3339 0.265194V2.62983H67.7372V11.7127H70.124V2.62983H73.5273V0.265194H64.3339Z" fill="black"/>
                    <path d="M82.2787 0C80.2455 0 78.5439 1.50276 78.5439 3.38122C78.5439 5.37017 80.2013 6.36464 81.8146 6.80663C82.6102 7.04972 83.8698 7.42542 83.8698 8.28729C83.8698 9.21547 82.9859 9.61326 82.1903 9.61326C81.0853 9.61326 80.1571 9.10497 79.3615 8.50829L78.1682 10.5193C79.2952 11.4033 80.577 12 82.2124 12C84.6875 12 86.2566 10.1657 86.2566 8.28729V8.26519C86.2566 6.32044 84.6654 5.14917 82.7649 4.64088C82.1461 4.44199 80.9306 4.0663 80.9306 3.38122C80.9306 2.69613 81.6599 2.32044 82.2787 2.32044C83.0963 2.32044 83.7151 2.62983 84.2455 2.96133L85.5715 1.06077C84.7317 0.552487 83.8256 0 82.2787 0Z" fill="black"/>
                    <path d="M88.0088 0.265194V7.31492C88.0088 10.0331 90.1304 12 92.6497 12C95.1469 12 97.2685 10.0331 97.2685 7.31492V0.265194H94.8596V7.24862C94.8596 8.72928 93.6 9.59116 92.6497 9.59116C91.6773 9.59116 90.4177 8.72928 90.4177 7.24862V0.265194H88.0088Z" fill="black"/>
                    <path d="M99.0375 0.265194V11.7127H101.358V9.25967H103.214C105.623 9.25967 107.524 7.20442 107.524 4.77348C107.524 2.34254 105.623 0.265194 103.214 0.265194H99.0375ZM101.358 2.58564H103.214C104.297 2.58564 105.203 3.51381 105.203 4.77348C105.203 6.01105 104.297 6.93923 103.214 6.93923H101.358V2.58564Z" fill="black"/>
                    <path d="M109.073 0.265194V11.7127H111.393V9.25967H113.25C115.659 9.25967 117.559 7.20442 117.559 4.77348C117.559 2.34254 115.659 0.265194 113.25 0.265194H109.073ZM111.393 2.58564H113.25C114.333 2.58564 115.239 3.51381 115.239 4.77348C115.239 6.01105 114.333 6.93923 113.25 6.93923H111.393V2.58564Z" fill="black"/>
                    <path d="M125.119 0C121.782 0 119.086 2.69613 119.086 6.01105C119.086 9.32597 121.782 12 125.119 12C128.456 12 131.152 9.32597 131.152 6.01105C131.152 2.69613 128.456 0 125.119 0ZM125.119 2.29834C127.152 2.29834 128.81 3.9558 128.81 6.01105C128.81 8.0442 127.152 9.70166 125.119 9.70166C123.086 9.70166 121.429 8.0442 121.429 6.01105C121.429 3.9558 123.086 2.29834 125.119 2.29834Z" fill="black"/>
                    <path d="M132.705 0.265194V11.7127H135.025V9.25967H135.887L138.384 11.7127H141.5L138.605 8.88398C140.152 8.1768 141.191 6.58564 141.191 4.77348C141.191 2.34254 139.29 0.265194 136.881 0.265194H132.705ZM135.025 2.58564H136.881C137.964 2.58564 138.87 3.51381 138.87 4.77348C138.87 6.01105 137.964 6.93923 136.881 6.93923H135.025V2.58564Z" fill="black"/>
                    <path d="M142.351 0.265194V2.62983H145.754V11.7127H148.141V2.62983H151.544V0.265194H142.351Z" fill="black"/>
                </svg>

            </div>
        </header>
    )
};

export default Header
