import React from 'react'
import cards from '../assets/cards/cards.png';

const Footer = ({data}) => {
    if (!data) return null;
    return (
        <footer className="footer">
            {data.name && (<p className={'footer-name'}>{data.name}</p>)}
            {data.address && (<p className={'footer-address'}>{data.address}</p>)}
            {data.email && (<a href={`mailto:${data.email}`} className={'footer-email'}>{data.email}</a>)}
            {data.phoneNumber && (<a href={`tel:${data.phoneNumber}`} className={'footer-phoneNumber'}>{data.phoneNumber}</a>)}
            {data.support && (<a href={`tel:${data.support}`} className={'footer-phoneNumber'}>{data.support} (Customer Support)</a>)}
            <div className={'footer-cards'}>
                <img src={cards} alt=""/>
            </div>
            <div className="footer_provide">We work with secure payment systems as PCI DSS COMPLAINT and Provide 100% Money Back Guarantee</div>
        </footer>
    )
};

export default Footer
