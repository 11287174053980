import React, {useState, useEffect} from 'react'
import Dropdown from "./Dropdown";
import { validateEmail, validateDate, validateAmount, validateCard } from '../utils/validators'
import useUploadFile from "../utils/useUploadFile";

const Form = ({toggleModal, translations}) => {
    const items = [{img: 'eng', key: 'USD'}, {img: 'eur', key: 'EUR'}, {img: 'gbr', key: 'GBR'}];
    const [currency, setCurrency] = useState('USD');
    const {uploadedFile, setUploadedFile, setIsLoading, isLoading} = useUploadFile();
    const initialState = {
        name: '',
        email: '',
        date: '',
        amount: '',
        pan: '',
        comment: ''
    };

    const host = window.location.host.replace('stage.', '');

    const [state, setState] = useState(initialState);
    const [errorsState, setErrorsState] = useState([]);

    useEffect(() => {
        if (uploadedFile) {
            setErrorsState(errorsState.filter(item => item !== 'attachment'))
        }
    }, [uploadedFile])



    const onChange = (field) => {
        return function handleChange(e) {
            switch (field) {
                case 'email' : {
                    setState({
                        ...state,
                        [field]: e.target.value
                    });
                    setErrorsState([...errorsState.filter(item => item !== 'email')]);
                    break;
                }
                /*case 'name' : {
                    setState({
                        ...state,
                        [field]: e.target.value
                    });
                    setErrorsState([...errorsState.filter(item => item !== 'name')]);
                    break;
                }*/
                case 'comment' : {
                    setState({
                        ...state,
                        [field]: e.target.value
                    });
                    setErrorsState([...errorsState.filter(item => item !== 'comment')]);
                    break;
                }
                case 'date' : {
                    const value = validateDate(state.date, e.target.value)
                    if (value === undefined) {
                        break;
                    }
                    setState({
                        ...state,
                        [field]: value
                    });
                    setErrorsState([...errorsState.filter(item => item !== 'date')]);
                    break;
                }
                case 'amount' : {
                    const value = validateAmount(state.amount, e.target.value)
                    if (value === undefined) {
                        break;
                    }
                    setState({
                        ...state,
                        [field]: value
                    });
                    setErrorsState([...errorsState.filter(item => item !== 'amount')]);
                    break;
                }
                case 'pan' : {
                    const value = validateCard(state.pan, e.target.value)
                    if (value === undefined) {
                        break;
                    }
                    setState({
                        ...state,
                        [field]: value
                    });
                    setErrorsState([...errorsState.filter(item => item !== 'pan')]);
                    break;
                }
                default: {
                    setState({
                        ...state,
                        [field]: e.target.value
                    });
                    break;
                }
            }

        }
    };

    const onBlur = (field) => {
        return function handleBlur(e) {
            switch (field) {
                case 'email' : {
                    let value = [];
                    if (!validateEmail(state.email)) {
                        value = new Set([...errorsState, 'email']);
                    } else {
                        value = errorsState.filter(item => item !== 'email');
                    }
                    setErrorsState([...value]);

                    break;
                }
                case 'date' : {
                    let value = [];
                    if (state.date.replace(/[^0-9]+/g, '').length < 8) {
                        value = new Set([...errorsState, 'date']);
                    } else {
                        value = errorsState.filter(item => item !== 'date');
                    }
                    setErrorsState([...value]);
                    break;
                }
                case 'amount' : {
                    let value = [];
                    if (state.amount.length < 1) {
                        value = new Set([...errorsState, 'amount']);
                        setErrorsState([...value]);
                    } else {
                        value = errorsState.filter(item => item !== 'amount');
                    }
                    setErrorsState([...value]);
                    break;
                }
                case 'pan' : {
                    let value = [];

                    if (state.pan.replace(/ /g,'').length < 16) {
                        value = new Set([...errorsState, 'pan']);
                    } else {
                        value = errorsState.filter(item => item !== 'pan');
                    }
                    setErrorsState([...value]);
                    break;
                }
                default: {
                    break;
                }
            }

        }
    };

    const handleSubmit = (e) => {
        e.preventDefault()
        const url = `https://${host}/feedback`;
        const data = {
            ...state,
            currency
        };

        let errors = [];

        Object.keys(data).forEach(item => {
            if (['email', 'amount', 'pan', 'date', 'comment'].includes(item) && !data[item]) {
                errors.push(item)
            }
        });

        /*if (!uploadedFile) {
            errors.push('attachment')
        }*/

        setErrorsState(errors);

        if (errors.length || errorsState.length) return;
        setIsLoading(true);

        if (uploadedFile) {
            let fileLoadedPercent = 13;
            let id = setInterval(function() {
                fileLoadedPercent += 40;
                if (fileLoadedPercent >= 100) {
                    clearInterval(id);
                    document.getElementById('progress_bar').style.width = '100%';
                } else {
                    document.getElementById('progress_bar').style.width = fileLoadedPercent + '%';
                }
            }, 800);
        }
        const formData = new FormData();
        if (uploadedFile) {
            formData.append('attachment', uploadedFile);
        }

        formData.append('name', data.name || '');
        formData.append('email', data.email);
        formData.append('date', data.date || '');
        formData.append('amount', data.amount);
        formData.append('pan', data.pan);
        formData.append('comment', data.comment || '');
        formData.append('currency', data.currency);

        fetch(url, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
            },
            body: formData
        }).then(res => res.json())
        .then(result => {
            if (result?.errors.length === 0) {
                setState(initialState);
                toggleModal(true);
            } else {
                alert(translations.tryAgain);
            }
            setIsLoading(false);
            setUploadedFile('');
        }).catch(err => {
            setIsLoading(false);
            setUploadedFile('');
            alert(translations.tryAgain);
        })
    };

    const hideDropwDowns = () => {
        document.querySelector('body').classList.add('hide_dropdown');
    };

    return (
        <form className="form" autoComplete="off">
            <h3>{translations.enterNameAndEmail}</h3>
            <label className={errorsState.includes('name') ? 'inputError' : ''}>
                <input type="text" placeholder="Full Name" value={state.name} onChange={onChange('name')} autoComplete="off"/>
                <p className="placeholderText">{translations.name}</p>
            </label>
            <label className={errorsState.includes('email') ? 'inputError' : ''}>
                <input type="email" placeholder="youremail@example.com" value={state.email} onChange={onChange('email')} onBlur={onBlur('email')} onFocus={hideDropwDowns} autoComplete="off"/>
                <p className="placeholderText">{translations.email}</p>
                <span>{translations.required}</span>
                <span className="errorText">{translations.errorEmail}</span>
            </label>
            <h3>{translations.enterDateAndAmount}</h3>
            <label className={errorsState.includes('date') ? 'inputError' : ''}>
                <input type="text" placeholder={'DD/MM/YYYY'} value={state.date} onChange={onChange('date')} onBlur={onBlur('date')} autoComplete="off"/>
                <p className="placeholderText">{translations.date}</p>
                <span>{translations.required}</span>
                <span className="errorText">{translations.errorDate}</span>
            </label>
            <div className='field-wrapper'>
                <label className={errorsState.includes('amount') ? 'inputError' : ''}>
                    <input type="text" placeholder="000" value={state.amount} onChange={onChange('amount')} onBlur={onBlur('amount')} autoComplete="off"/>
                    <p className="placeholderText">{translations.amount}</p>
                    <span>{translations.required}</span>
                    <span className="errorText">{translations.errorAmount}</span>
                </label>
                <div className='dropdown-wrap'>
                    <Dropdown
                        showTitle={true}
                        items={items}
                        cb={setCurrency}
                        canSetTop={true}
                    />
                </div>
            </div>
            <h3>{translations.firstSix}</h3>
            <label className={errorsState.includes('pan') ? 'inputError' : ''}>
                <input type="text" placeholder={'0000 00XX XXXX 0000'} value={state.pan} onChange={onChange('pan')} onBlur={onBlur('pan')} autoComplete="off"/>
                <p className="placeholderText">{translations.card}</p>
                <span>{translations.required}</span>
                <span className="errorText">{translations.errorCard}</span>
            </label>
            <h3>{translations.issueDetails}</h3>
            <div className={'comment_wrap'}>
            <textarea  className={errorsState.includes('comment') ? 'inputError' : ''} placeholder={translations.otherInfo}  value={state.comment} onChange={onChange('comment')} autoComplete="off"/>
                <span className={'comment_required_label'}>{translations.required}</span>
            </div>
            <div
                id="drop-region"
                className={`field_cv ${errorsState.includes('attachment') ? 'inputError' : ''} ${uploadedFile ? 'dropped' : ''} ${isLoading ? 'disabled' : ''}`}
            >
                <input type="file" id="drop_image" accept={'image/jpeg, image/jpg, image/png'}
                       autoComplete="off" />
                <div id="progress_bar" className={!uploadedFile ? 'empty' : ''}/>
                <div>
                    {uploadedFile ? (
                        <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12.5002 13.3333C13.2074 13.3333 13.8857 13.0524 14.3858 12.5523C14.8859 12.0522 15.1668 11.3739 15.1668 10.6667C15.1668 9.95942 14.8859 9.28115 14.3858 8.78105C13.8857 8.28095 13.2074 8 12.5002 8C11.7929 8 11.1146 8.28095 10.6145 8.78105C10.1144 9.28115 9.8335 9.95942 9.8335 10.6667C9.8335 11.3739 10.1144 12.0522 10.6145 12.5523C11.1146 13.0524 11.7929 13.3333 12.5002 13.3333Z" stroke="#191D32" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M17.8332 2.66663H12.4998C5.83317 2.66663 3.1665 5.33329 3.1665 12V20C3.1665 26.6666 5.83317 29.3333 12.4998 29.3333H20.4998C27.1665 29.3333 29.8332 26.6666 29.8332 20V13.3333" stroke="#191D32" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M4.06006 25.2667L10.6334 20.8534C11.6867 20.1467 13.2067 20.2267 14.1534 21.04L14.5934 21.4267C15.6334 22.32 17.3134 22.32 18.3534 21.4267L23.9001 16.6667C24.9401 15.7734 26.6201 15.7734 27.6601 16.6667L29.8334 18.5334" stroke="#191D32" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M19.5 7L22.5 10L28.5 4" stroke="#1BC972" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>

                    ) : (
                    <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12.5 13.3333C13.2073 13.3333 13.8855 13.0524 14.3856 12.5523C14.8857 12.0522 15.1667 11.3739 15.1667 10.6667C15.1667 9.95942 14.8857 9.28115 14.3856 8.78105C13.8855 8.28095 13.2073 8 12.5 8C11.7928 8 11.1145 8.28095 10.6144 8.78105C10.1143 9.28115 9.83334 9.95942 9.83334 10.6667C9.83334 11.3739 10.1143 12.0522 10.6144 12.5523C11.1145 13.0524 11.7928 13.3333 12.5 13.3333Z" stroke="#191D32" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M17.8333 2.66666H12.5C5.83332 2.66666 3.16666 5.33332 3.16666 12V20C3.16666 26.6667 5.83332 29.3333 12.5 29.3333H20.5C27.1667 29.3333 29.8333 26.6667 29.8333 20V13.3333" stroke="#191D32" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M24.5 2.66666V10.6667M24.5 10.6667L27.1667 7.99999M24.5 10.6667L21.8333 7.99999M4.06 25.2667L10.6333 20.8533C11.6867 20.1467 13.2067 20.2267 14.1533 21.04L14.5933 21.4267C15.6333 22.32 17.3133 22.32 18.3533 21.4267L23.9 16.6667C24.94 15.7733 26.62 15.7733 27.66 16.6667L29.8333 18.5333" stroke="#191D32" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
                    )}
                </div>
                <div className="title">{translations.attachFile}</div>
                <div className="subtitle">{translations.dragAndDrop}</div>
                <div className="dropped_file_message">
                    <span id="dropped_file_message">{isLoading ? 'Loading...' : uploadedFile?.name}</span>
                        <div id="remove_dropped_file" className={isLoading ? '' : 'active'}>
                            delete
                        </div>
                </div>
            </div>
            <button type={'submit'} onClick={handleSubmit}>{translations.sendRequest}</button>
        </form>
    )
};

export default Form
