export const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
};

export const validateDate = (oldValue, newValue) => {
    const fieldValue = newValue.replace(/[^0-9]+/g, '');
    if (fieldValue.length > 8) return;
    if (oldValue === fieldValue) return;

    const result = [...fieldValue];

    Array.from(fieldValue).forEach((item, index)=> {
        if(index === 2) {
            result.splice(2, 0, ' / ')
        }
        if(index === 4) {
            result.splice(5, 0, ' / ')
        }
    });

    const curYear = new Date().getFullYear();


    const splitedDate = result.join('').split(' / ');
    if (+splitedDate[0] > 31) {
        splitedDate[0] = '31'
    }
    if (splitedDate[0]?.length === 2 && +splitedDate[0] === 0 && !!fieldValue) {
        splitedDate[0] = '01'
    }
    if (+splitedDate[1] > 12) {
        splitedDate[1] = '12'
    }

    if (splitedDate[1]?.length === 2 && +splitedDate[1] === 0) {
        splitedDate[1] = '01'
    }
    if (+splitedDate[2] > curYear) {
        splitedDate[2] = `${curYear}`
    }
    if (splitedDate[2]?.length === 4 && +splitedDate[2] < 2010) {
        splitedDate[2] = `${curYear}`
    }

    return splitedDate.join(' / ')
};

export const validateCard = (oldValue, newValue) => {
    const fieldValue = newValue.replace(/[^0-9]+/g, '');
    if (fieldValue.length > 10) return;
    if (oldValue === fieldValue) return;

    if (newValue === '') {
        return newValue
    }

    if (fieldValue.length > 6) {
        return fieldValue.replace(/[^\dA-Z]/g, '').replace(/(.{6})/, '$1XXXXXX').match(/.{1,4}/g).join(' ');
    } else {
        return fieldValue.replace(/[^\dA-Z]/g, '').match(/.{1,4}/g).join(' ');
    }
};

export const validateAmount = (oldValue, newValue) => {
    if (!newValue) return '';

    const isValid = (/^\d{0,5}([,.]\d{0,2})?$/).test(newValue);
    if (isValid) {
        return newValue;
    }
    return oldValue
};
