import React from 'react'

const Description = ({translations}) => {
    return (
        <div className="description">
            <h1>{translations.title}</h1>
            <p>{translations.description}</p>
        </div>
    )
};

export default Description
